// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-crypto-js": () => import("./../../../src/pages/crypto.js" /* webpackChunkName: "component---src-pages-crypto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loans-js": () => import("./../../../src/pages/loans.js" /* webpackChunkName: "component---src-pages-loans-js" */),
  "component---src-pages-mortgages-js": () => import("./../../../src/pages/mortgages.js" /* webpackChunkName: "component---src-pages-mortgages-js" */)
}

